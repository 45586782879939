import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/auth.models';
import { CompanyUser } from '../models/companyUser.models';

export enum PipeUserType {
  USERNAME = 'username',
  ROLE = 'role',
  ADDRESS = 'address',
  INITIALS = 'initials',
}

@Pipe({
  name: 'xUser',
  pure: false,
})
export class XUserPipe implements PipeTransform {
  constructor() {}

  transform(value: any, type?: any): string {
    let result: string = '';
    if (value) {
      switch (type) {
        case PipeUserType.USERNAME: {
          result = new User(value).username;
          break;
        }
        case PipeUserType.ROLE: {
          result = new User(value).roleDescription;
          break;
        }
        case PipeUserType.ADDRESS: {
          result = new CompanyUser(value).addressComplete;
          break;
        }
        case PipeUserType.INITIALS: {
          result = new User(value).initials;
          break;
        }
        default: {
          result = '';
          break;
        }
      }
    }
    return result;
  }
}
