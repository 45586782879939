import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../models/company.models';

export enum PipeCompanyType {
  STATUS = 'status',
  ADDRESS = 'address',
  STATUS_COLOR = 'statusColor',
}

@Pipe({
  name: 'xCompany',
  pure: false,
})
export class XCompanyPipe implements PipeTransform {
  constructor() {}

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeCompanyType.STATUS: {
        result = new Company(value).statusDescription;
        break;
      }
      case PipeCompanyType.ADDRESS: {
        result = new Company(value).addressComplete;
        break;
      }
      case PipeCompanyType.STATUS_COLOR: {
        result = new Company(value).statusColor;
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }
}
