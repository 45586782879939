import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xCurrency',
  pure: false,
})
export class XCurrencyPipe implements PipeTransform {
  constructor() {}

  transform(value: any, currency: string): any {
    return formatCurrency(Number(value), 'pt', getCurrencySymbol(currency, 'narrow', 'pt'), '1.2-2');
  }
}
