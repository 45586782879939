import { Company } from './company.models';
import { Configuration } from './configuration.models';
import { getVehicleType } from './helpers/getVehicleType';

export enum VehicleType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  SEMI = 'SEMI',
}

export class Vehicle {
  id: number;
  licensePlate: string;
  brand: string | null;
  model: string | null;
  deleted: boolean;
  companyId: string;
  categoryId: string;
  type: VehicleType | null;

  company: Company | null;
  category: Configuration | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj?.id ?? '';

    this.licensePlate = $obj?.licensePlate ?? '';
    this.brand = $obj?.brand ?? null;
    this.model = $obj?.model ?? null;
    this.deleted = $obj?.deleted ?? false;
    this.companyId = $obj.companyId ?? $obj.company?.id ?? null;
    this.categoryId = $obj.categoryId ?? $obj.category?.id ?? null;
    this.type = $obj.type ?? null;

    this.company = $obj?.company ? new Company($obj.company) : null;
    this.category = $obj?.category ? new Configuration($obj.category) : null;

    this.createdAt = $obj?.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj?.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get statusDescription() {
    return getVehicleType(this.type);
  }
}
