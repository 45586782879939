import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http: HttpClient, private modalSrv: NzModalService) {}

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url: string, frequency: number = 1000 * 60) {
    this.checkVersion(url);
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches
    this.http
      .get(url + '?t=' + new Date().getTime(), {
        headers: {
          'no-error': 'true',
        },
      })
      .subscribe(
        (resp: any) => {
          const hash = resp.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            // for an example: location.reload();

            const modal: NzModalRef = this.modalSrv.warning({
              nzClosable: false,
              nzCentered: true,
              // nzCancelText: 'Cancelar',
              // nzOkText: 'Atualizar',
              nzTitle: 'Nova versão disponível',
              nzContent:
                'Existe uma nova versão da aplicação disponível por favor atualize para obter as ultima funcionalidades.',
              nzFooter: [
                {
                  label: 'Atualizar',
                  type: 'primary',
                  onClick: () =>
                    new Promise((resolve) => {
                      setTimeout(resolve, 1000);
                      location.reload();
                    }),
                },
              ],
              // nzOnOk: () => new Promise(resolve => {
              //   setTimeout(resolve, 1000);
              //   location.reload();
              // }),
              // nzOnCancel: () => new Promise(resolve => {
              //   setTimeout(resolve, 500);
              // })
            });
          }

          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
