import { Pipe, PipeTransform } from '@angular/core';

export enum PipeVehicleType {
  CATEGORY = 'category',
}

@Pipe({
  name: 'xVehicle',
  pure: false,
})
export class XVehiclePipe implements PipeTransform {
  constructor() {}

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeVehicleType.CATEGORY: {
        result = '';
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }
}
