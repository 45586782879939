import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SystemConfigurationService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, protected authSrv: AuthService) {}

  findAll(pageSize: number, page: number, search?: string, filters?: any, sort?: any) {
    let params = new HttpParams();

    params = params.appendAll({
      pageSize,
      page,
    });

    if (search && search != '') {
      params = params.appendAll({
        searchFields: 'type',
        search,
      });
    }

    let uri = `${environment.API_URL}/api/system-configuration/lastestVersions`;
    return this.http.get<any[]>(uri);
  }

  getAll() {
    let uri = `${environment.API_URL}/api/system-configuration/lastestVersions`;
    return this.http.get<any[]>(uri);
  }

  create(data: any): any {
    let uri = `${environment.API_URL}/api/system-configuration`;
    return this.http.post<any>(uri, data, { headers: this.headers });
  }

  findOne(id: string) {
    let params = new HttpParams();

    let uri = `${environment.API_URL}/api/system-configuration/${id}`;
    return this.http.get<any[]>(uri, { params });
  }

  getByType(type: string) {
    let params = new HttpParams();
    let uri = `${environment.API_URL}/api/system-configuration/lastestVersion/${type}`;
    return this.http.get<any[]>(uri, { params });
  }

  update(id: string, data: any) {
    let uri = `${environment.API_URL}/api/system-configuration/${id}`;
    return this.http.patch<any[]>(uri, data, { headers: this.headers });
  }

  delete(id: string): any {
    let uri = `${environment.API_URL}/api/system-configuration/${id}`;
    return this.http.delete<any>(uri, { headers: this.headers });
  }
}
