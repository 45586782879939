import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authSrv: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authSrv.currentUser;

    let isGuidesRoute = (route as any)._urlSegment?.segments?.[0]?.path === 'guides';
    let guideId = (route as any)._urlSegment?.segments?.[1]?.path;
    if (isGuidesRoute && guideId) {
      let guideToken = route.queryParams['t'] ?? '';
      if (guideToken !== '') {
        if (await this.authSrv.validateGuideToken(guideId, guideToken)) {
          return true;
        }
      }
    }

    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return true;
  }
}
