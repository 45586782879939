import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { User } from '../models/auth.models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Company } from '../models/company.models';
import { ActivatedRoute } from '@angular/router';
import { GuideToken } from '../models/guideToken.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  isRefreshing = false;
  $isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private user: User | null = null;
  private company: Company | null = null;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {}

  get currentUser(): User | null {
    return this.user;
  }

  get companySelected(): Company | null {
    return this.company;
  }

  selectCompany(company: Company | null) {
    this.company = company;
  }

  /**
   * Performs the login auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string): any {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/auth/login`,
        {
          email,
          password,
        },
        {
          headers: new HttpHeaders().append('no-token', 'true'),
          withCredentials: true,
        }
      )
      .pipe(
        map((res) => {
          // login successful if there's a jwt token in the response
          if (res.data[0] && res.data[0].accessToken) {
            // store user details and jwt in session
            res.data[0].payload.token = res.data[0].accessToken;
            res.data[0].payload.expiresIn = new Date(Date.now() + res.data[0].expiresIn);

            this.user = new User(res.data[0].payload);

            this.setRefreshingToken(false);
          }

          return res;
        })
      );
  }

  /**
   * Performs the signup auth
   * @param name name of user
   * @param email email of user
   * @param password password of user
   */
  signup(data: any): any {
    return this.http.post<any>(`${environment.API_URL}/api/company`, data, { headers: this.headers });
  }

  logoutAPI() {
    return this.http.post(
      `${environment.API_URL}/api/auth/logout`,
      {},
      {
        headers: new HttpHeaders().append('no-token', 'true'),
        withCredentials: true,
      }
    );
  }

  /**
   * Logout the user
   */
  logout() {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/auth/logout`,
        {},
        {
          headers: new HttpHeaders().append('no-token', 'true'),
          withCredentials: true,
        }
      )
      .pipe(
        first(),
        tap((resp: any) => {
          this.user = null;
        })
      );
  }

  initialRefreshToken(): Observable<any> {
    return this.refreshToken().pipe(catchError(() => of(true)));
  }

  /**
   * Refresh Token
   * Gets a new access token from the refreshtoken present in the cookies
   */
  refreshToken(): Observable<any> {
    return new Observable((observer) => {
      this.setRefreshingToken(true);

      return this.http
        .post<any>(
          `${environment.API_URL}/api/auth/refreshToken`,
          {},
          {
            headers: {
              'no-error': 'true',
              'no-token': 'true',
            },
            withCredentials: true,
          }
        )
        .pipe(finalize(() => this.setRefreshingToken(false)))
        .subscribe(
          (res) => {
            let result = res.data?.[0] ?? null;

            if (!result) {
              observer.error();
              observer.complete();
              return;
            }

            this.user = new User(result.payload);

            this.user.token = result.accessToken;
            this.user.expiresIn = new Date(Date.now() + result.expiresIn);

            observer.next(res);
            observer.complete();
          },
          (error: any) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  private setRefreshingToken(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
    this.$isRefreshing.next(this.isRefreshing);
  }

  hasRole(role: string): boolean {
    if (this.user) {
      return this.user.role.includes(role);
    } else {
      return false;
    }
  }

  recoverPassword(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/passwordReset`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }

  recoverAccount(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/passwordRecovery`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }

  verificationAccount(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/verificationAdmin`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }

  guideToken: any = null;
  async validateGuideToken(guideId: string, guideToken: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let uri = `${environment.API_URL}/api/guide/${guideId}/checkAccess?guideToken=${guideToken}`;
      return this.http.get<any>(uri, {}).subscribe(
        (resp) => {
          if (!resp.data.length) {
            resolve(false);
            return;
          }

          this.guideToken = new GuideToken({ ...resp.data[0] });
          resolve(true);
        },
        (error: any) => {
          console.warn(`AuthService ~ validateGuideToken:`, error);
          resolve(false);
        }
      );
    });
  }
}
