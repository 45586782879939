import { Pipe, PipeTransform } from '@angular/core';
import { SystemConfiguration } from '../models/system-configuration.models';

export enum PipeSystemConfigType {
  TYPE = 'type',
}

@Pipe({
  name: 'xSystemConfig',
  pure: false,
})
export class XSystemConfigPipe implements PipeTransform {
  constructor() {}

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeSystemConfigType.TYPE: {
        result = new SystemConfiguration(value).typeDescription;
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }
}
