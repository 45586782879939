import { GuideAccessType } from './guide.models';

export class GuideToken {
  id: string;
  guideId: string;
  companyId: string | null;
  userId: string | null;
  email: string;
  type: GuideAccessType | null;
  canEditGuide: boolean;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj?.id ?? null;

    this.guideId = $obj?.guideId ?? null;
    this.companyId = $obj?.companyId ?? null;
    this.userId = $obj?.userId ?? null;
    this.email = $obj?.email ?? null;
    this.type = $obj?.type ?? null;
    this.canEditGuide = $obj?.canEditGuide ?? false;

    this.createdAt = $obj?.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj?.updatedAt ? new Date($obj.updatedAt) : new Date();
  }
}
