import { Pipe, PipeTransform } from '@angular/core';
import { Registration } from '../models/registration.models';

export enum PipeRegistrationType {
  STATUS = 'status',
  DURATION = 'duration',
  SCHEDULLER = 'scheduller',
  IMAGE = 'image',
  DISTANCE = 'distance',
}

@Pipe({
  name: 'xRegistration',
  pure: false,
})
export class XRegistrationPipe implements PipeTransform {
  constructor() {}

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeRegistrationType.STATUS: {
        result = new Registration(value).statusDescription;
        break;
      }
      case PipeRegistrationType.DURATION: {
        result = new Registration(value).duration;
        break;
      }
      case PipeRegistrationType.SCHEDULLER: {
        result = new Registration(value).scheduller;
        break;
      }
      case PipeRegistrationType.IMAGE: {
        result = new Registration(value).image;
        break;
      }
      case PipeRegistrationType.DISTANCE: {
        result = new Registration(value).distance.toString();
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }
}
