import { Company } from './company.models';
import { CompanyUser } from './companyUser.models';

export enum LicenseStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CONSULT = 'CONSULT',
  DELETED = 'DELETED',
}

export class License {
  id: number;
  from: Date;
  to: Date;
  value: number;
  status: LicenseStatus;

  companyId: string | null;
  companyUserId: string | null;
  createdById: string | null;
  updatedById: string | null;

  company: Company | null;
  companyUser: CompanyUser | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';

    this.from = $obj && $obj.from ? new Date($obj.from) : new Date();
    this.to = $obj && $obj.to ? new Date($obj.to) : new Date();
    this.value = $obj && $obj.value ? $obj.value : 0;
    this.status = $obj && $obj.status ? $obj.status : LicenseStatus.PENDING;

    this.companyId = $obj && $obj.companyId ? $obj.companyId : $obj && $obj.company ? $obj.company.id : null;
    this.companyUserId =
      $obj && $obj.companyUserId ? $obj.companyUserId : $obj && $obj.companyUser ? $obj.companyUser.id : null;
    this.createdById = $obj && $obj.createdById ? $obj.createdById : null;
    this.updatedById = $obj && $obj.updatedById ? $obj.updatedById : null;

    this.company = $obj && $obj.company ? new Company($obj.company) : null;
    this.companyUser = $obj && $obj.companyUser ? new CompanyUser($obj.companyUser) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get statusDescription() {
    let description = '';

    switch (this.status) {
      case LicenseStatus.PENDING: {
        description = 'Por Atribuir';
        break;
      }
      case LicenseStatus.APPROVED: {
        description = 'Atribuida';
        break;
      }
      case LicenseStatus.DELETED: {
        description = 'Inativa';
        break;
      }
      case LicenseStatus.NEW: {
        description = 'Nova';
        break;
      }
      default: {
        description = 'Por Atribuir';
        break;
      }
    }

    return description;
  }

  get statusColor() {
    let description = '';

    switch (this.status) {
      case LicenseStatus.PENDING: {
        description = 'yellow';
        break;
      }
      case LicenseStatus.APPROVED: {
        description = 'green';
        break;
      }
      case LicenseStatus.DELETED: {
        description = 'red';
        break;
      }
      case LicenseStatus.NEW: {
        description = 'gray';
        break;
      }
      default: {
        description = 'yellow';
        break;
      }
    }

    return description;
  }
}
