<nz-modal
  class="privacy"
  [(nzVisible)]="showModal"
  [nzWidth]="600"
  (nzOnCancel)="logout()"
  nzCancelText="Cancelar"
  nzOkText="Aceitar"
  [nzOkLoading]="saving"
  (nzOnOk)="save()"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [nzOkDisabled]="disableSave"
  [nzKeyboard]="false"
  [nzCloseOnNavigation]="false"
  [nzBodyStyle]="{ padding: '0', overflow: 'unset' }"
>
  <ng-container *nzModalContent>
    <div #content class="content" [scrollTop]="0" (scroll)="onScroll($event)">
      <div #privacyPolicyElem [innerHtml]="systemConfig?.text"></div>
    </div>
  </ng-container>
</nz-modal>
