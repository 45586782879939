import { isArray } from 'underscore';
import { Company } from './company.models';
import { CompanyUser } from './companyUser.models';
import { Country } from './country.models';

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ANTRAM = 'ANTRAM',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum LoginType {
  NONE = 'NONE',
  DEMO = 'DEMO',
  DEBT = 'DEBT',
  VIEW = 'VIEW',
}

export class User {
  id: string;
  name: string | null;
  email: string | null;
  nif: string | null;
  photo: string | null;
  role: UserRole;

  privacyPolicyAccepted: boolean;
  privacyDate: Date | null;
  showAlertRenovation: boolean;

  canEditDeleteUsers: boolean;
  canApplyDiscounts: boolean;
  canCancelInvoices: boolean;
  issueLicensesWithoutInvoice: boolean;

  loginType: LoginType;
  password: string;
  pin: string;
  token: string;
  expiresIn: Date | null;

  createdById: string | null;
  updatedById: string | null;
  countryId: number | null;
  companyId: string | null;
  companyUserId: string | null;

  createdBy: User | null;
  updatedBy: User | null;
  country: Country | null;
  company: Company | null;
  companyUser: CompanyUser | null;
  companyUsers: CompanyUser[];

  lastLogin: Date | null;
  lastLogout: Date | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj?.id ?? '';

    this.name = $obj?.name ?? null;
    this.email = $obj?.email ?? null;
    this.nif = $obj?.nif ?? null;
    this.photo = $obj?.photo ?? null;
    this.role = $obj?.role ?? UserRole.USER;

    this.privacyPolicyAccepted = $obj?.privacyPolicyAccepted ?? false;
    this.privacyDate = $obj?.privacyDate ?? false;
    this.showAlertRenovation = $obj?.showAlertRenovation ?? false;

    this.canEditDeleteUsers = $obj?.canEditDeleteUsers ?? false;
    this.canApplyDiscounts = $obj?.canApplyDiscounts ?? false;
    this.canCancelInvoices = $obj?.canCancelInvoices ?? false;
    this.issueLicensesWithoutInvoice = $obj?.issueLicensesWithoutInvoice ?? false;

    this.loginType = $obj?.loginType ?? null;
    this.password = $obj?.password ?? null;
    this.pin = $obj?.pin ?? null;
    this.token = $obj?.token ?? null;
    this.expiresIn = $obj.expiresIn ? new Date($obj.expiresIn) : null;

    this.createdById = $obj?.createdById ?? $obj?.createdBy?.id ?? null;
    this.updatedById = $obj.updatedById ?? $obj?.updatedBy?.id ?? null;
    this.companyId = $obj?.companyId ?? $obj?.company?.id ?? null;
    this.companyUserId = $obj?.companyUserId ?? $obj.companyUser?.id ?? null;

    this.createdBy = $obj?.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj?.updatedBy ? new User($obj.updatedBy) : null;
    this.country = $obj?.country ? new Country($obj.country) : null;
    this.company = $obj?.company ? new Company($obj.company) : null;
    this.companyUser = $obj?.companyUser ? new CompanyUser($obj.companyUser) : null;
    this.companyUsers =
      $obj?.companyUsers && isArray($obj.companyUsers) && $obj.companyUsers.length > 0
        ? $obj.companyUsers.map((m: any) => new CompanyUser(m))
        : [];

    this.lastLogin = $obj?.lastLogin ? new Date($obj.lastLogin) : null;
    this.lastLogout = $obj?.lastLogout ? new Date($obj.lastLogout) : null;

    this.createdAt = $obj?.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj?.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get isSuperAdmin() {
    return this.role === UserRole.SUPERADMIN ? true : false;
  }
  get isAntram() {
    return this.role === UserRole.ANTRAM ? true : false;
  }
  get isAdmin() {
    return this.role === UserRole.ADMIN ? true : false;
  }
  get isUser() {
    return this.role === UserRole.USER ? true : false;
  }

  get username() {
    const nameSplit = this.name?.split(' ');
    return this.name ? (nameSplit && nameSplit.length > 0 ? nameSplit[0] : this.name) : '';
  }

  get roleDescription() {
    let description: string;
    switch (this.role) {
      case UserRole.SUPERADMIN: {
        description = 'Administrador do Sistema';
        break;
      }
      case UserRole.ANTRAM: {
        description = 'Antram';
        break;
      }
      case UserRole.ADMIN: {
        description = 'Administrador';
        break;
      }
      case UserRole.USER: {
        description = 'Utilizador';
        break;
      }
      default: {
        description = 'Utilizador';
        break;
      }
    }
    return description;
  }

  get initials() {
    return (
      this.name
        ?.split(' ')
        .map((n: string) => n.substring(0, 1))
        .join('')
        .toUpperCase() || ''
    );
  }
}
