import { User } from './auth.models';

export enum SystemConfigurationType {
  PRIVACY = 'PRIVACY',
  HELP_PWA = 'HELP_PWA',
  HELP_BO = 'HELP_BO',
}

export class SystemConfiguration {
  id: string;
  text: string;
  type: SystemConfigurationType;

  createdById: string | null;
  updatedById: string | null;

  createdBy: User | null;
  updatedBy: User | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.text = $obj && $obj.text ? $obj.text : null;
    this.type = $obj && $obj.type ? $obj.type : null;

    this.createdById = $obj && $obj.createdById ? $obj.createdById : $obj && $obj.createdBy ? $obj.createdBy.id : null;
    this.updatedById = $obj && $obj.updatedById ? $obj.updatedById : $obj && $obj.updatedBy ? $obj.updatedBy.id : null;

    this.createdBy = $obj && $obj.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj && $obj.updatedBy ? new User($obj.updatedBy) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get typeDescription() {
    let description = '';

    switch (this.type) {
      case SystemConfigurationType.PRIVACY: {
        description = 'Política de Privacidade';
        break;
      }
      case SystemConfigurationType.HELP_PWA: {
        description = 'Manual de Utilização (APP)';
        break;
      }
      case SystemConfigurationType.HELP_BO: {
        description = 'Manual de Utilização (Web)';
        break;
      }
      default: {
        description = '';
        break;
      }
    }

    return description;
  }
}
