import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private authservice: AuthService) {}

  transform(hasPermission: string | string[]): boolean {
    return true;
  }
}
